import FilterListIcon from '@mui/icons-material/FilterList';
import { IconButton } from '@mui/material';
import * as React from 'react';
import { type OnFilterApply } from 'components/ReportTable';
import { theme } from 'styles/mui-theme';
import { TritonTooltip } from 'styles/styles';

export const FilterButton = <FilterKey extends string>({
    filterKey,
    value,
    onFilterApply,
}: {
    filterKey: FilterKey;
    value: string;
    // onFilterApply may be null if filters are disabled.
    // to avoid conditionally rendering, we support null here and hide the button.
    onFilterApply: OnFilterApply<FilterKey> | null;
}): React.ReactElement | null => {
    if (!onFilterApply) {
        return null;
    }

    return (
        <TritonTooltip title="Apply filter">
            <IconButton
                size="small"
                css={{
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: -4,
                    background: theme.tritonColors.darkBlue,
                    color: 'white',
                    padding: 3,
                    '&:hover': {
                        background: theme.tritonColors.skyBlue,
                    },
                }}
                onClick={() => {
                    onFilterApply(filterKey, [value]);
                }}
            >
                <FilterListIcon css={{ fontSize: 15 }} />
            </IconButton>
        </TritonTooltip>
    );
};
