import CloseIcon from '@mui/icons-material/Close';
import { alpha, Box, Grid, IconButton, Stack, styled, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, defineMessage, type MessageDescriptor } from 'react-intl';
import { ActivePodcasts } from 'components/ActivePodcasts';
import { FilterButton } from 'components/FilterButton';
import { FormattedNumber } from 'components/FormattedNumber';
import { ImageFade } from 'components/ImageFade';
import { RankChange as RankChangeComponent } from 'components/RankChange';
import { type OnFilterApply } from 'components/ReportTable';
import { formatNumber } from 'helpers/number';
import { getPodcastImageUrl } from 'helpers/urls';
import { type Company, type Podcast, type RowCompanies } from 'report/getReport';
import { type RankChange } from 'report/reportTypes';
import { theme } from 'styles/mui-theme';
import { makeFlexWrappedGrid, TritonDivider } from 'styles/styles';

export const ReportOverlay = ({
    reportTitle,
    reportSetTitle,
    periodLabel,
    children,
    onClose,
}: React.PropsWithChildren<{
    reportTitle: string;
    reportSetTitle: string;
    periodLabel: string;
    children: React.ReactNode;
    onClose: () => void;
}>) => {
    return (
        <Stack sx={{ width: '100%', padding: 2 }} spacing={1}>
            <OverlayHeader onClose={onClose}>
                <Typography
                    variant="h3"
                    css={{
                        color: theme.tritonColors.darkBlue,
                        fontWeight: 'normal',
                        fontSize: '1.2em',
                        lineHeight: 1.4,
                    }}
                >
                    {reportTitle} - {reportSetTitle} - {periodLabel}
                </Typography>
            </OverlayHeader>
            <TritonDivider />
            {children}
        </Stack>
    );
};

export const ReportOverlayGrid = ({ children }: React.PropsWithChildren<unknown>) => {
    return (
        <Box py={1}>
            <Grid container spacing={2}>
                {children}
            </Grid>
        </Box>
    );
};

export const ReportOverlayGridActivePodcasts = ({ row }: { row: { activePodcasts: number } }) => {
    return (
        <GridChild1
            messageDescriptor={defineMessage({
                defaultMessage: 'Active podcasts',
                description: 'Report overlay active podcasts',
            })}
        >
            <ActivePodcasts value={row.activePodcasts} />
        </GridChild1>
    );
};

export const ReportOverlayGridAverageWeeklyDownloads = ({ row }: { row: { averageWeeklyDownloads: number } }) => {
    return (
        <GridChild1
            messageDescriptor={defineMessage({
                defaultMessage: 'Avg weekly downloads',
                description: 'Report overlay average weekly downloads',
            })}
        >
            <FormattedNumber value={row.averageWeeklyDownloads} />
        </GridChild1>
    );
};

export const ReportOverlayGridAverageWeeklyUsers = ({ row }: { row: { averageWeeklyUsers: number } }) => {
    return (
        <GridChild1
            messageDescriptor={defineMessage({
                defaultMessage: 'Avg weekly users',
                description: 'Report overlay average weekly users',
            })}
        >
            <FormattedNumber value={row.averageWeeklyUsers} />
        </GridChild1>
    );
};

export const ReportOverlayGridMonthly = ({
    row,
}: {
    row: { monthlyDownloads?: number; monthlyListeners?: number };
}) => {
    const monthlyDownloads = React.useMemo(
        () => row.monthlyDownloads && formatNumber(row.monthlyDownloads),
        [row.monthlyDownloads],
    );

    const monthlyListeners = React.useMemo(
        () => row.monthlyListeners && formatNumber(row.monthlyListeners),
        [row.monthlyListeners],
    );

    return (
        <>
            {monthlyListeners && (
                <GridChild1
                    messageDescriptor={defineMessage({
                        defaultMessage: 'Monthly listeners',
                        description: 'Report overlay monthly listeners',
                    })}
                >
                    {monthlyListeners}
                </GridChild1>
            )}
            {monthlyDownloads && (
                <GridChild1
                    messageDescriptor={defineMessage({
                        defaultMessage: 'Monthly downloads',
                        description: 'Report overlay monthly downloads',
                    })}
                >
                    {monthlyDownloads}
                </GridChild1>
            )}
        </>
    );
};

const networkLabel = defineMessage({
    defaultMessage: 'Network',
    description: 'Report overlay network',
});

export const ReportOverlayGridNetwork = ({
    networks,
    getCompanyUrl,
    onFilterApply,
}: {
    networks: RowCompanies;
    getCompanyUrl: (company: Company) => string | null;
    onFilterApply: OnFilterApply<'network'> | null;
}) => {
    return (
        <GridChildCompanies
            messageDescriptor={networkLabel}
            companies={networks}
            filterButton={<FilterButton filterKey="network" value={networks.name} onFilterApply={onFilterApply} />}
            getCompanyUrl={getCompanyUrl}
        />
    );
};

export const ReportOverlayGridNewEpisodes = ({ row }: { row: { newEpisodes: number } }) => {
    const newEpisodes = React.useMemo(() => formatNumber(row.newEpisodes), [row.newEpisodes]);

    return (
        <GridChild1
            messageDescriptor={defineMessage({
                defaultMessage: 'New episodes',
                description: 'Report overlay new episodes',
            })}
        >
            {newEpisodes}
        </GridChild1>
    );
};

export const ReportOverlayGridPublishersRepresented = ({
    row,
    getCompanyUrl,
}: {
    getCompanyUrl: (company: Company) => string | null;
    row: { publishers: RowCompanies };
}) => {
    return (
        <GridChildCompanies
            messageDescriptor={defineMessage({
                defaultMessage: 'Publishers represented',
                description: 'Report overlay publishers represented',
            })}
            companies={row.publishers}
            filterButton={null}
            getCompanyUrl={getCompanyUrl}
        />
    );
};

export const ReportOverlayGridRank = ({ row }: { row: { rank: number; rankChange: RankChange } }) => {
    return (
        <GridChild1
            messageDescriptor={defineMessage({
                defaultMessage: 'Rank',
                description: 'Report overlay rank',
            })}
        >
            {row.rank} <RankChangeComponent rankChange={row.rankChange} hideNoChangeIcon />
        </GridChild1>
    );
};

export const ReportOverlayGridSalesRepresentative = ({
    row,
    getCompanyUrl,
    onFilterApply,
}: {
    row: { salesRepresentatives: RowCompanies };
    getCompanyUrl: (company: Company) => string | null;
    onFilterApply: OnFilterApply<'salesRepresentative'> | null;
}) => {
    return (
        <GridChildCompanies
            messageDescriptor={defineMessage({
                defaultMessage: 'Sales representation',
                description: 'Report overlay sales representation',
            })}
            companies={row.salesRepresentatives}
            filterButton={
                <FilterButton
                    filterKey="salesRepresentative"
                    value={row.salesRepresentatives.name}
                    onFilterApply={onFilterApply}
                />
            }
            getCompanyUrl={getCompanyUrl}
        />
    );
};

export const ReportOverlayPodcast = <CompanyFilterKey extends string>({
    row,
    companyFilterKey,
    company,
    onFilterApply,
}: {
    row: { category: string | null; podcast: Podcast };
    companyFilterKey: CompanyFilterKey | null;
    company: RowCompanies | null;
    onFilterApply: OnFilterApply<CompanyFilterKey | 'category'> | null;
}) => {
    return (
        <BlueBox>
            <Grid container spacing={2}>
                <>
                    <Grid item xs={12} md="auto" textAlign="center">
                        <Box
                            display="inline-block"
                            width={200}
                            height={200}
                            css={{
                                background: row.podcast.imageDominantColor,
                                [theme.breakpoints.down('md')]: {
                                    width: 120,
                                    height: 120,
                                },
                            }}
                        >
                            <ImageFade
                                src={getPodcastImageUrl(row.podcast)}
                                alt={row.podcast.title}
                                width="100%"
                                height="100%"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md textAlign={{ xs: 'center', md: 'left' }}>
                        <Stack spacing={1}>
                            <Stack
                                direction={{ xs: 'column', md: 'row' }}
                                spacing={1}
                                alignItems={{
                                    md: 'baseline',
                                }}
                            >
                                <OverlayTextTwo
                                    css={{
                                        flex: '1 1 auto',
                                    }}
                                >
                                    {row.podcast.title}
                                </OverlayTextTwo>
                                {company
                                    ? company.data.map((individualCompany, index, array) => {
                                          const isLastItem = index === array.length - 1;

                                          return (
                                              <OverlayTextOne
                                                  key={individualCompany.name}
                                                  css={{
                                                      // we don't want the filter button on its own line
                                                      flex: 'none',
                                                  }}
                                              >
                                                  <ExternalLinkOptional url={individualCompany.creatorUrl}>
                                                      {individualCompany.name}
                                                  </ExternalLinkOptional>
                                                  {!isLastItem ? ' /' : null}
                                                  {isLastItem && companyFilterKey !== null && onFilterApply ? (
                                                      <FilterButton
                                                          filterKey={companyFilterKey}
                                                          value={company.name}
                                                          onFilterApply={onFilterApply}
                                                      />
                                                  ) : null}
                                              </OverlayTextOne>
                                          );
                                      })
                                    : null}
                            </Stack>
                            <OverlayTextOne>
                                {row.category ? (
                                    <>
                                        {row.category}
                                        <FilterButton
                                            filterKey="category"
                                            value={row.category}
                                            onFilterApply={onFilterApply}
                                        />
                                    </>
                                ) : (
                                    'N/A'
                                )}
                            </OverlayTextOne>
                            {row.podcast.description && (
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: theme.tritonColors.darkBlue,
                                    }}
                                >
                                    {row.podcast.description}
                                </Typography>
                            )}
                        </Stack>
                    </Grid>
                </>
            </Grid>
        </BlueBox>
    );
};

export const ReportOverlayText = ({ children }: React.PropsWithChildren<unknown>) => {
    return (
        <BlueBox>
            <OverlayTextTwo>{children}</OverlayTextTwo>
        </BlueBox>
    );
};

const BlueBox = ({ children }: React.PropsWithChildren<unknown>) => {
    return (
        <Box sx={{ backgroundColor: alpha(theme.tritonColors.paleBlue, 0.15) }}>
            <TritonDivider />
            <Box p={2}>{children}</Box>
            <TritonDivider />
        </Box>
    );
};

const OverlayHeader = ({ children, onClose }: React.PropsWithChildren<{ onClose: () => void }>) => {
    return (
        <Grid container>
            <Grid item xs>
                {children}
            </Grid>
            <Grid item xs="auto">
                <IconButton
                    size="small"
                    sx={{
                        backgroundColor: theme.tritonColors.darkBlue,
                        color: 'white',
                        '&:hover': {
                            backgroundColor: theme.tritonColors.skyBlue,
                        },
                    }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

const OverlayHeading = styled(Typography)(() => ({
    color: theme.tritonColors.darkBlue,
    opacity: 0.6,
    fontSize: 14,
}));

const OverlayTextOne = styled(Typography)(() => ({
    color: theme.tritonColors.darkBlue,
    fontSize: 19,
}));

const OverlayTextTwo = styled(Typography)(() => ({
    color: theme.tritonColors.darkBlue,
    fontSize: 26,
    fontWeight: 500,
}));

const GridChild1 = ({
    messageDescriptor,
    children,
}: React.PropsWithChildren<{ messageDescriptor: MessageDescriptor }>) => {
    return (
        <Grid item md={3} xs={6}>
            <OverlayHeading>
                <FormattedMessage {...messageDescriptor} />
            </OverlayHeading>
            <OverlayTextOne>{children}</OverlayTextOne>
        </Grid>
    );
};

const gridChildCompaniesGrid = makeFlexWrappedGrid(theme.spacing(2), theme.spacing(2));

const GridChildCompanies = ({
    messageDescriptor,
    companies,
    filterButton,
    getCompanyUrl,
}: {
    messageDescriptor: MessageDescriptor;
    companies: RowCompanies;
    filterButton: JSX.Element | null;
    getCompanyUrl: (company: Company) => string | null;
}) => {
    return (
        <Grid item>
            <OverlayHeading
                css={{
                    marginBottom: theme.spacing(2),
                }}
            >
                <FormattedMessage {...messageDescriptor} />
                {filterButton}
            </OverlayHeading>
            <div css={gridChildCompaniesGrid.parentCss}>
                {companies.data.map((company) => {
                    return (
                        <div key={company.name} css={[gridChildCompaniesGrid.childCss]}>
                            <ReportOverlayCompanyLogo company={company} url={getCompanyUrl(company)} />
                        </div>
                    );
                })}
            </div>
        </Grid>
    );
};

const logoWidth = 120;
const logoHeight = 60;

export function ReportOverlayCompanyLogo({ company, url }: { company: Company; url: string | null }) {
    return (
        <ExternalLinkOptional url={url}>
            <div
                css={{
                    padding: theme.spacing(1),
                    border: `2px solid ${theme.palette.grey[200]}`,
                }}
            >
                {company.logo ? (
                    <img
                        alt={company.name}
                        title={company.name}
                        src={company.logo.url}
                        css={{
                            display: 'block',
                            objectFit: 'contain',
                            width: logoWidth,
                            height: logoHeight,
                        }}
                    />
                ) : (
                    <OverlayTextOne
                        css={{
                            display: 'flex',
                            alignItems: 'center',
                            height: logoHeight,
                            // prevent very short company names looking strange
                            justifyContent: 'center',
                            minWidth: '4em',
                        }}
                    >
                        {company.name}
                    </OverlayTextOne>
                )}
            </div>
        </ExternalLinkOptional>
    );
}

export function SimpleGridOverlay({ name, value }: { name: string; value: number | undefined }) {
    return (
        <>
            <Grid item xs={6}>
                <OverlayHeading>{name}</OverlayHeading>
            </Grid>
            <Grid item xs={6}>
                <OverlayTextOne>{value !== undefined ? value : 'N/A'}</OverlayTextOne>
            </Grid>
        </>
    );
}

function ExternalLinkOptional({ url, children }: React.PropsWithChildren<{ url: string | null }>) {
    if (url) {
        return (
            <a href={url} target="_blank" rel="noreferrer">
                {children}
            </a>
        );
    } else {
        return <>{children}</>;
    }
}
